<template>
	<div class="kema-book" :data-idx="book.idx"
		:style="{
			background: `url(${featuredImage})`
		}">
		<div class="spine">
      <div class="title">
        <h3>{{book.name}}</h3>
      </div>
    </div>
    <div class="contents">
    
		</div>
	</div>
</template>

<script>
export default {
	name: 'kema-book',
	data: () => ({
		imageIndex: 0,
	}),
	props: {
		book: {
			type: Object,
			required: true,
		},
	},
	computed: {
		featuredImage() {
			return this.book.images[this.imageIndex]
		},
	},
}
</script>

<style lang="stylus" scoped>
@require '../style/colors.styl'
@require '../style/mixins.styl'

.kema-book {
	position: relative
	flexXY(start, auto)
	size(100px, 100%)
	overflow: hidden
	background-size: cover

	transition: 0.5s all ease-in-out
	&:first-child {
		border-bottom-left-radius: 12px
		border-top-left-radius: 12px
	}
	&:last-child {
		border-bottom-right-radius: 12px
		border-top-right-radius: 12px
	}
	&.open {
		width: 100%
	}

	div.spine {
    position: relative
    size(100px, 100%)
    flexXY(center, center)
    color: #eee
    overflow: hidden
    &::before {
      content: ''
      absPos(0, null, null, 0)
      size(128px, calc(100% + 28px))
      margin: -14px
      filter: blur(8px)
			background-size: cover

    }
    &::after {
      content: ''
      absPos(0, 0, 0, 0)
      background: rgba(0, 0, 0, 0.25)
      z-index: 0
			cursor: pointer
    }
    .title {
      flexXY(center, center)
      pointer-events: none
      z-index: 1
    }
    
    h3 {
      display: inline-block
      transform: rotate(-90deg)
      white-space:nowrap
      color: lighten($color1, 50%)
			text-transform uppercase
			letter-spacing: 0.14em
    }
  }
}
</style>
