<template>
  <div class="kema-nav">
    <div class="header" @click="() => $router.push({path: '/'})" title="Home">
      <logo-sm/>
      <div class="title">
        <h5>Kenneth E. Martin</h5>
        <h5 class="highlight">Architect</h5>
      </div>
    </div>
    <nav>
      <ul>
        <li v-for="(n, key) in menuRoutes" :key="key">
          <router-link :to="n.path">
            <div :class="`navitem_${n.name}`">{{n.name}}</div>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import routes from './router/config'
import LogoSm from './logo/LogoSm'

export default {
  name: 'kema-nav',
  components: {LogoSm},
  props: ['data'],
  computed: {
    menuRoutes() {
      return Object.values(routes).filter((route) => !(['Home', 'Contact'].includes(route.name)))
    },
  },
}
</script>

<style lang="stylus" scoped>
@require './style/colors.styl'

.kema-nav {
  color: #333
  position: relative
  padding: 16px
  .header {
    width: 92px
    height: 92px
    display: flex
    justify-content: center
    align-items: center
    background: $color1
    box-shadow: 0 0px 20px $color1
    border-radius: 46px
    cursor: pointer
    .title {
      position: absolute !important
      display: none
    }
  }
}

.kema-nav {
  nav {
    margin-top: 42px
    ul {
      margin: 0
      padding: 0
      list-style: none
      li {
        text-transform uppercase
        letter-spacing: 0.24em
        cursor: pointer
        padding: 6px 16px
        &:last-child {
          border-bottom: none
        }
        a {
          text-decoration: none
          transition: 0.4s all
          color: darken($color1, 40%)
          &.router-link-exact-active {
            div {
              color: darken($color1, 70%)
            }
          }
        }
        div:hover {
          background: linear-gradient(-90deg, lighten($color1, 5%), $c_nav-bg)
        }
        div > i {
          padding: 16px 16px
          width: 60px
          color: $c_highlight
          transition: 0.3s all
        }
      }
    }
  }
}
</style>

