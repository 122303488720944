export default {
  state: {
    audio: {
      volume: 0,
    },
    projects: [
      {
        name: 'Delaware Street Residences',
        images: [
          '/static/img/delaware_house_2.jpg',
          '/static/img/delaware_house.jpg',
        ],
      },
      {
        name: 'Brookmont Carriage House',
        images: [
          '/static/img/carriage_house.jpg',
          '/static/img/carriage_house_detail.jpg',
        ],
      },
    ],
  },
  getters: {
    volume: (state) => state.audio.volume,
  },
  mutations: {
    setVolume: (state, val) => {
      if(!state.audio) return
      state.audio.volume = val
    },
  },
}