<template>
  <section class="history">
    <h2 class="banner">History</h2>
    <hr>
    <article>
      <p>The firm Kenneth E. Martin Architect encompasses a 42 year project crossroad, from pharmaceutical to corporate, from residential to adaptive re-use.</p>
    </article>
  </section>
</template>

<script>
export default {
  name: '',
}
</script>

<style lang="stylus" scoped>
</style>
