<template>
  <div class="logo-sm logo logoH-32">
    <div class="cycle">
      <div>
        <div>
          <div>
            <div class="cycle">
              <div>
                <div>
                  <div>
                    <div class="cycle">
                      <div>
                        <div>
                          <div>
                            <div class="cycle"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'logo-sm',
}
</script>

<style lang="stylus" src="../style/golden-spiral.styl" scoped></style>
<style lang="stylus" scoped>

</style>

