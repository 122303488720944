import { render, staticRenderFns } from "./KemaNav.vue?vue&type=template&id=2db2ff81&scoped=true&"
import script from "./KemaNav.vue?vue&type=script&lang=js&"
export * from "./KemaNav.vue?vue&type=script&lang=js&"
import style0 from "./KemaNav.vue?vue&type=style&index=0&id=2db2ff81&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2db2ff81",
  null
  
)

export default component.exports