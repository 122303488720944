<template>
  <section class="news">
    <h2 class="banner">News</h2>
    <hr>
    <article class="badge" :data-badge="'11.2017'">
      <p>KeMA has moved office to Morrison, Colorado serving Jefferson County and the Front Range.</p>
      <br><br>
      <p>Athletic, Adaptive Reuse, Commercial, Industrial, Interiors, Judicial, Medical, MMJ/H, Pharmaceutical,
        Life Services, Project Representation, Real Estate, Recreation, Religious, Residential, Transportation,
        Urban, and Code Analysis.</p>
    </article>
  </section>
</template>

<script>
export default {
  name: '',
}
</script>

<style lang="stylus" scoped>
@require './style/colors.styl'
@require './style/mixins.styl'

article.badge {
  position: relative
  overflow: inherit
  padding: 32px 0
  &::before {
    content: attr(data-badge)
    color: lighten($color1, 80%)
    font-family: 'Didact Gothic', sans-serif
    text-align center
    line-height: 2em
    font-weight bold
    letter-spacing: 0.2em
    absPos(-16px, null, null, -16px)
    size(100px, 33px)
    background: $color2
    border-radius: 16px
  }
}
</style>
