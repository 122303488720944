import { render, staticRenderFns } from "./LogoSm.vue?vue&type=template&id=54832564&scoped=true&"
import script from "./LogoSm.vue?vue&type=script&lang=js&"
export * from "./LogoSm.vue?vue&type=script&lang=js&"
import style0 from "../style/golden-spiral.styl?vue&type=style&index=0&id=54832564&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54832564",
  null
  
)

export default component.exports