import 'font-awesome/css/font-awesome.css'
import './static/fonts/Swiss721BT.css'

// import Bloom from './effects/bloom'
import Vue from 'vue'

import Vuex from 'vuex'
import Store from './store'

import VueRouter from 'vue-router'
import routes from './router/config'

import App from './App.vue'
import Home from './Home.vue'
import Philosophy from './Philosophy.vue'
import History from './History.vue'
import News from './News.vue'
import Contact from './Contact.vue'
import Projects from './Projects.vue'

const mapComponent = (componentName) => {
	switch(componentName) {
		case 'Home': return Home;
		case 'Philosophy': return Philosophy;
		case 'History': return History;
		case 'News': return News;
		case 'Contact': return Contact;
		case 'Projects': return Projects;
		default: throw new Error(`Cannot map '${componentName}' to a component.`)
	}
}

Vue.use(Vuex)
const store = new Vuex.Store(Store)

Vue.use(VueRouter)
const router = new VueRouter({
	mode: 'history',
	routes: routes.map((r) => ({component: mapComponent(r.name), ...r})),
})

new Vue({
	el: '#app',
	store,
	router,
	render: h => h(App),
})

// let bloom = new Bloom({
// 	radius: null,
// 	damp: 3,
// 	resolution: 3,
// })
// store.state.audio = bloom.audio

// Font-awesome test
// document.body.innerHTML = '<i class="fa fa-fw fa-question"></i>';
