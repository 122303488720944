<template>
  <div class="contact">
    <a href="https://network.aia.org/people/kenneth-martin" target="_blank">
      <sub>AIA</sub>
    </a>
    <a href="https://www.linkedin.com/in/kenneth-e-martin-42216418/" target="_blank">
      <i class="fa fa-linkedin"></i>
    </a>
    <a href="mailto:kmartinarchitect@gmail.com">
      <i class="fa fa-envelope-o"></i>
    </a>
  </div>
</template>

<script>
export default {
  name: 'Contact',
}
</script>

<style lang="stylus" scoped>
@require './style/colors.styl'

.contact {
  position fixed
  bottom: 0
  left: 0
  width: 100%
  padding: 6px 16px
  display: flex
  justify-content: center
  align-items: center
  background: #f4f5f7
  & > * {
    padding: 0 8px
    cursor: pointer
    &:last-child {
      padding-right: 0
    }
    i.fa {
      font-size: 1.3em
    }
  }
  sub {
    font-size: 1.1em
    font-weight: bold
  }
  a {
    cursor: hover
    text-decoration none
  }
}
</style>

