<template>
  <div id="app" class="app">
    <kema-nav/>
    <div class="content">
      <transition name="fade">
        <router-view/>
      </transition>
    </div>
    <contact/>
  </div>
</template>
<script>
import {mapMutations, mapGetters} from 'vuex'
import KemaNav from './KemaNav.vue'
import Contact from './Contact.vue'

export default {
  name: 'App',
  components: {KemaNav, Contact},
  data() {
    return {
      title: 'Kema',
      subtitle: 'Kenneth E. Martin Architect',
      motto: '"Together, walking design paths toward creative solutions."',
    }
  },
  mounted() {
    const nav = Array.from(document.getElementsByClassName('kema-nav'))[0]
    const contact = Array.from(document.getElementsByClassName('contact'))[0]
    if(!nav || !contact) return
    contact.style.paddingLeft = nav.clientWidth + 'px'
  },
}
</script>

<style lang="stylus">
@require './style/main.styl'

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter-active {
  transition-delay: 0.5s
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>

