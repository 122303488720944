const routes = [
  {name: 'Home', path: '/'},
  {name: 'Projects', path: '/projects'},
  {name: 'Philosophy', path: '/philosophy'},
  {name: 'History', path: '/history'},
  {name: 'News', path: '/news'},
  {name: 'Contact', path: '/contact'},
]

export default routes
