export default ((className) => {
	const elements = Array.from(document.getElementsByClassName(className))
	const clickEvents = elements.map((el) => {
		const children = Array.from(el.children)
		const spine = children.find((c) => Array.from(c.classList).includes('spine'))
		return spine.addEventListener('click', open)
	})
	function open(e) {
		const idx = parseInt(e.target.parentElement.dataset.idx)
		elements.forEach((el, i) => {
			if(i === idx) el.classList.toggle('open')
			else el.classList.remove('open')
		})
	}
})