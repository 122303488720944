<template>
	<div class="projects">
		<h2 class="banner">Projects</h2>
		<hr>
		<kema-shelf :books="projects"/>
	</div>
</template>

<script>
import KemaShelf from './gallery/KemaShelf.vue'
import {mapState} from 'vuex'

export default {
	name: 'Projects',
	components: {KemaShelf},
	computed: {
		...mapState(['projects']),
	},
}
</script>

<style lang="stylus" scoped>
@require './style/colors.styl'
.projects {
	width: 98%
}
</style>

