<template>
  <div class="logo" :class="['logoH-' + width, {minimal: minimal || false}]">
    <div class="overlay">
      <div class="center">
        <div class="diag diag1"></div>
        <div class="diag diag2"></div>
      </div>
    </div>
    <div class="kema">
      <span class="logo-title logo-k">{{k}}</span>
      <span class="logo-title logo-e">{{e}}</span>
      <span class="logo-title logo-m">{{m}}</span>
      <span class="logo-title logo-a">{{a}}</span>
    </div>
    <div class="cycle">
      <div>
        <div>
          <div>
            <div class="cycle">
              <div>
                <div>
                  <div>
                    <div class="cycle">
                      <div>
                        <div>
                          <div>
                            <div class="cycle"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LogoSm from './LogoSm.vue'

export default {
  name: 'logo',
  props: ['width', 'minimal'],
  components: {LogoSm},
  data() {
    return {
      amSize: 8,
      k: 'k',
      e: 'E',
      m: 'm',
      a: 'a',
    }
  },
  computed: {
    amHeight() {
      return this.width - (this.width % this.amSize)
    },
  },
}
</script>

<style lang="stylus" src="../style/golden-spiral.styl" scoped></style>

<style lang="stylus" scoped>
@require '../style/colors.styl'

$c_logo_bg = #333

.horiz-line::after {
  position: absolute
  top: -2px
  left: -297px
  right: -220px
  border-top: 1px solid $c_hidden_border
  content: ''
}

.vert-line::after {
  position: absolute
  left: -2px
  top: -196px
  bottom: -160px
  border-left: 1px solid $c_hidden_border
  width: 100%
  content: ''
}

.fill {
  background: $c_logo_bg
  background: linear-gradient(45deg, $c_logo_bg 0%,#81c681 100%)
}

.kema {
  position: relative
  font-size: 14px
  width: 100%
  height: 100%
  margin-top: 40px
  margin-left: 40px
}
.logo-title {
  position: absolute
  font-family: 'Swis721 LtEx BT'
  font-size: 9em
  color: $color2
  z-index: 1
}
.logo-k {
  left: 8px
  top: 0
}
.logo-e {
  font-size: 3.2em
  left: 92px
  top: 106px
}
.logo-m {
  left: 125px
  top: 0
}
.logo-a {
  left: 260px
  top: 0
}

.logo .overlay
  .center
    position: absolute
    right: 27.2%
    bottom: 27.2%
    width: 500px
    height: 500px
    transform: translate(50%, 50%)
    transform-origin: 257% -109%
    transform: rotate(-15.5deg)
    z-index: 2
    .diag
      position: absolute
      bottom: 0
      height: 700px
      width: 1px
      background: #333
    .diag1
      left: 0
      transform-origin: 100% 100%
      transform: rotate(45deg)
    .diag2
      right: 0
      transform-origin: 100% 100%
      transform: rotate(-45deg)
</style>
