<template>
  <section class="philosophy">
    <h2 class="banner">Philosophy</h2>
    <hr>
    <article>
      <p>Incorporating a critical systematic approach to realizing environments of knowledge, value, reason, and language.</p>
    </article>
  </section>
</template>
<script>
export default {
  name: 'Philosophy',
  data() {
    return {
      title: 'Philosophy',
      tag: 'Incorporating a critical systematic approach to realizing environments of knowledge, value, reason, and language.',
    }
  },
}
</script>
