<template>
  <div class="home">
    <div>
      <logo :width="240" :minimal="false"></logo>
      <p>Together, walking design paths &nbsp; toward creative solutions.</p>
    </div>
  </div>
</template>

<script>
import Logo from './logo/Logo'

export default {
  name: 'home',
  components: {Logo},
  data() {
    return {}
  },
}
</script>

<style lang="stylus" scoped>
.home {
  margin-top: 20vh
  position: relative
  display: flex
  justify-content: center
  p {
    font-size: 1.14em
  }
}
</style>

