<template>
	<div class="kema-shelf">
		<kema-book v-for="(b, i) in books" :book="{...b, idx: i}" :key="b.name"/>
	</div>
</template>

<script>
import KemaBook from './KemaBook.vue'
import Bookshelf from './Bookshelf.js'

export default {
	name: 'kema-shelf',
	components: {KemaBook},
	props: {
		books: {
			type: Array,
			required: true,
		},
	},
	mounted() {
		this.$nextTick(() => {
			new Bookshelf('kema-book')
		})
	},
}
</script>

<style lang="stylus" scoped>
@require '../style/mixins.styl'

.kema-shelf {
  margin: 0 auto
  flexXY(center, center)
  size(98%, 700px)
}
</style>

